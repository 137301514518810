import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139')
];

export const server_loads = [0,3,4,14];

export const dictionary = {
		"/": [28],
		"/activities": [29],
		"/activities/clone": [~32],
		"/activities/create": [33,[3]],
		"/activities/create/backdoor": [34,[3]],
		"/activities/edit/[activityId]": [35,[4]],
		"/activities/[activity]": [30],
		"/activities/[state]/[year]": [31,[2]],
		"/blog": [36],
		"/blog/posts/[slug]": [37,[5]],
		"/call-for-counselors": [38],
		"/camp": [39],
		"/cfp": [40],
		"/communities": [41,[6]],
		"/communities/[community]": [42,[6,7]],
		"/events": [44],
		"/events/(thatConferences)/tx": [46,[10]],
		"/events/(thatConferences)/tx/[year]": [47,[10]],
		"/events/(thatConferences)/tx/[year]/schedule": [48,[10]],
		"/events/(thatConferences)/tx/[year]/sponsors": [49,[10]],
		"/events/(thatConferences)/tx/[year]/tickets": [50,[10]],
		"/events/(thatConferences)/tx/[year]/travel": [51,[10]],
		"/events/(thatConferences)/wi": [52,[10]],
		"/events/(thatConferences)/wi/[year]": [53,[10]],
		"/events/(thatConferences)/wi/[year]/schedule": [54,[10]],
		"/events/(thatConferences)/wi/[year]/sponsors": [55,[10]],
		"/events/(thatConferences)/wi/[year]/tickets": [56,[10]],
		"/events/(thatConferences)/wi/[year]/travel": [57,[10]],
		"/events/(online)/[event]/[date]": [45,[9]],
		"/event/check-in/[eventName]/[year]": [~43,[8]],
		"/family": [58],
		"/join/access-denied": [60],
		"/join/[activityId]": [~59,[11]],
		"/login-redirect": [61],
		"/membership": [65],
		"/membership/pricing": [66],
		"/members": [63,[12]],
		"/members/[member]": [64,[12,13]],
		"/my": [67,[14]],
		"/my/favorites": [68,[14]],
		"/my/network": [69,[14,15]],
		"/my/network/sponsors": [70,[14,15]],
		"/my/notification-preferences": [71,[14,16]],
		"/my/profiles": [72,[14,17]],
		"/my/profiles/emergency-contact": [73,[14,17]],
		"/my/profiles/primary": [74,[14,17]],
		"/my/profiles/shared": [75,[14,17]],
		"/my/profiles/slack": [76,[14,17]],
		"/my/settings": [77,[14,18]],
		"/my/settings/badges": [78,[14,18]],
		"/my/settings/membership": [79,[14,18]],
		"/my/settings/order-history": [80,[14,18]],
		"/my/settings/tickets": [81,[14,18]],
		"/my/submissions": [82,[14,19]],
		"/m/[member]": [62],
		"/newsletter": [84],
		"/newsletter/verify-your-humanity": [85],
		"/newsletter/what-now": [86],
		"/news/submit": [83,[20]],
		"/orders/canceled": [89],
		"/orders/(orders)/claim": [87,[21]],
		"/orders/success": [90,[22]],
		"/orders/success/claim-ticket": [91,[22]],
		"/orders/success/expo-hall-ticket": [92,[22]],
		"/orders/(orders)/summary": [88,[21]],
		"/partners": [93,[23]],
		"/partners/job-board": [97,[23]],
		"/partners/leads": [98,[23,24]],
		"/partners/leads/[eventId]": [99,[23,24]],
		"/partners/my-network": [~100,[23,25]],
		"/partners/past": [101,[23]],
		"/partners/[partner]": [94,[23]],
		"/partners/[partner]/jobs/[job]": [95,[23]],
		"/partners/[state]/[year]": [96,[23]],
		"/promo": [102],
		"/releases/change-log": [103],
		"/releases/changelog-missed": [105],
		"/releases/changelog": [104],
		"/releases/missed": [106],
		"/sessions/[id]": [107],
		"/signs": [108],
		"/signup": [109],
		"/speakers/accept": [110,[26]],
		"/speakers/declined": [111,[26]],
		"/sponsorships/apply": [112,[27]],
		"/sponsorships/prospectus": [113,[27]],
		"/support": [114],
		"/support/anti-harassment": [115],
		"/support/code-of-conduct": [116],
		"/support/commitment-to-diversity": [117],
		"/support/contact-us": [118],
		"/support/copyright": [119],
		"/support/covid-policies": [120],
		"/support/creating-an-activity": [121],
		"/support/faq": [122],
		"/support/joining-an-activity": [123],
		"/support/my-favorites-icalendar": [124],
		"/support/privacy-policy": [125],
		"/support/sponsors/expo-hall": [126],
		"/support/sponsors/forms": [127],
		"/support/staying-up-to-date": [128],
		"/support/terms-of-use": [129],
		"/support/that-field-guide": [130],
		"/support/transfer-a-ticket": [131],
		"/support/travel": [132],
		"/support/travel/tx": [133],
		"/support/travel/wi": [134],
		"/support/what-is-an-activity": [135],
		"/that-conference": [136],
		"/tx": [137],
		"/verify-account": [138],
		"/wi": [139]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';